.frontpage {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.frontpage .header {
    font-family: 'IBM Plex Sans', sans-serif;
    line-height: 1.2;
    display: flex;
    align-items: center;
}

.frontpage .header > .title {
    font-size: 70px;
    line-height: 1.3;
    font-weight: 700;
}

.frontpage .header > .definition {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    font-size: 21px;
    line-height: 1.2;
    margin-left: auto;
}

.frontpage h1 {
    font-size: 60px;
    font-weight: 400;

    line-height: 1.2;
    margin-bottom: 8px;
}

.frontpage .section {
    margin-top: 90px;
}

.frontpage .panes-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
}

.frontpage .pane {
    display: flex;

    border-radius: 7px;
    margin-bottom: 10px;
    text-decoration: none !important;

    color: white !important;

    transition: opacity 0.3s ease;
}

.frontpage .pane:hover {
    opacity: 0.9;
}

.frontpage .pane h2 {
    margin-top: 6px;
    margin-left: 10px;
    font-weight: 100;
    font-size: 60px;
    line-height: 1.2;
}

.frontpage .pane .vis-wrapper {
    padding-left: 10px;
}

.frontpage .sorts-collection {
    display: flex;
    flex-direction: row;
    height: 370px;
    width: 100%;
}

.frontpage .bubble-sort {
    background-color: #8700ff;
    margin-right: 10px;
    height: 370px;
}

.frontpage .quick-sort {
    background-color: #e3444a;
    height: 370px;
}

.frontpage .sort-1 {
    width: 50%;
}

.frontpage .sort-2 {
    width: 50%;
}

.frontpage .simplified-hash-collisions {
    background-color: #4d9e38;
    width: 100%;
    margin-right: 10px;
}

.frontpage .simplified-hash-search {
    background-color: #f45c26;
    height: 50%;
}

.frontpage .simplified-hash-create {
    background-color: #8700ff;
    height: 50%;
}

.frontpage .simplified-hash-collection {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 370px;
}

.frontpage .simplified-hash-collection-left {
    display: flex;
    width: 50%;
}

.frontpage .simplified-hash-collection-right {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.frontpage .hash-collection {
    display: flex;
    flex-direction: column;
}

.frontpage .hash-collection-top {
    display: flex;
    flex-direction: row;
    height: 180px;
}

.frontpage .hash-collection-bottom {
    display: flex;
    flex-direction: row;
    height: 180px;
}

.frontpage .hash-create {
    width: 58%;
    background-color: #f5c451;
    color: black !important;
    margin-right: 10px;
}

.frontpage .hash-search {
    width: 42%;
    background-color: #3faaef;
}

.frontpage .hash-remove {
    width: 42%;
    background-color: #1f7a78;
    margin-right: 10px;
}

.frontpage .hash-resize {
    width: 58%;
    background-color: #e3444a;
}

@media screen and (max-width: 890px) {
    .frontpage {
        padding-left: 10px;
        padding-right: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    .frontpage .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .frontpage .header > .title {
        font-size: 26px;
        line-height: 1.3;
        font-weight: 700;
    }

    .frontpage .header > .definition {
        flex-direction: row;

        padding-top: 5px;
        font-size: 12px;
        line-height: 1.2;
        padding-left: 0px;
        margin-left: 0px;
    }

    .frontpage .header > .definition > .definition-1:after {
        content: '\00a0';
    }

    .frontpage h1 {
        font-size: 26px;
        font-weight: 400;

        line-height: 1.2;
    }

    .frontpage .section {
        margin-top: 45px;
    }

    .frontpage .panes-container {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
    }

    .frontpage .pane {
        display: flex;

        border-radius: 5px;
        margin-bottom: 5px;
        text-decoration: none !important;

        color: white !important;
    }

    .frontpage .pane h2 {
        font-weight: 200;

        margin-top: 6px;
        margin-left: 10px;
        font-size: 34px;
    }

    .frontpage .hash-collection {
        height: 300px;
    }

    .frontpage .hash-collection-top {
        width: 100%;
        height: 50%;
        flex-direction: column;
    }

    .frontpage .hash-collection-bottom {
        width: 100%;
        height: 50%;
        flex-direction: column;
    }

    .frontpage .hash-collection-bottom > *,
    .frontpage .hash-collection-top > * {
        height: 50%;
        width: 100%;
    }

    .frontpage .simplified-hash-collection {
        height: 210px;
        flex-direction: column;
    }

    .frontpage .simplified-hash-collection > * {
        width: 100%;
    }

    .frontpage .hash-remove {
        margin-right: 5px;
    }

    .frontpage .hash-remove {
        margin-right: 5px;
    }

    .frontpage .simplified-hash-collisions {
        margin-right: 0px;
        height: 70px;
    }

    .frontpage .simplified-hash-search,
    .frontpage .simplified-hash-create,
    .frontpage .bubble-sort,
    .frontpage .quick-sort {
        margin-right: 0px;
        height: 70px;
    }

    .frontpage .sort-2,
    .frontpage .sort-1 {
        width: 100%;
    }

    .frontpage .sorts-collection {
        height: 140px;
        flex-direction: column;
    }

    .frontpage .hash-create {
        color: black !important;
        margin-right: 5px;
    }

    a.link {
        font-size: 12px;
    }
}
